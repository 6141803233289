
export const errorMess = {
  "1001": "not login",
}

export const NotLoginCode = 1001;

export const ApiIsLoginError = (error) => {
  if (error instanceof Map) {
    // eslint-disable-next-line no-irregular-whitespace
    // トークン有効性判断　U000053 U000054 U000055 U000072 U000045存在チェック
    if (error.has("U000053") ||
      error.has("U000054") ||
      error.has("U000055") ||
      error.has("U000072") ||
      error.has("U000045")) {
      return true
    }
  }

  return false
}

class ApiError extends Error {

  ErrorCode = 0;

  constructor(message) {
    super(message);
    this.name = "Api";
  }
}

export default ApiError;