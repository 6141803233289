import PropTypes from 'prop-types';
import { useSiteContext } from '../context/site-context';

export default function PageView({ children }) {
  const {site} = useSiteContext();


  if (!site) {
    return <div>サイトを選択してください。</div>;
  }

  return children
}

PageView.propTypes = {
  children: PropTypes.node,
};