import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const AuthContext = createContext({});

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuthContext must be use inside AuthProvider');

  return context;
}
