import { useCallback, useEffect } from 'react';
import { Text } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useSiteContext } from '../../components/site/context/site-context';


export default function SitePopover() {

  const {site, sites, setSite} = useSiteContext();

  const popover = usePopover();

  const handleChangeSite = useCallback(
    (newSite) => {
      setSite(newSite);
      popover.onClose();
    },
    [popover, setSite]
  );

  if (sites.length === 0) {
    return ""
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={popover.onOpen}>
        {site ? site.name : "?"}
      </Button>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {sites.map((option) => (
          <MenuItem
            key={option.site_id}
            selected={option.site_id === site?.site_id}
            onClick={() => handleChangeSite(option)}
          >
            {option.name}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  )
}
