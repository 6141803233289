import useSWR from 'swr';
import { useMemo } from 'react';

import { httpGet, httpPost } from 'src/utils/cms-axios';

export function getType(data) {

  const {site_id, type} = data

  return httpGet("/post/type", {
    "type": type,
  }, {
    "cms-site-id": site_id,
  });
}

export function getTypeList(data) {

  const {site_id} = data

  return httpGet("/post/type/list", {}, {
    "cms-site-id": site_id,
  });
}

export function getPost(data) {

  const {site_id, post_id, post_type_id} = data

  return httpGet("/post", {
    "post_type_id": post_type_id,
    "post_id": post_id,
  }, {
    "cms-site-id": site_id,
  });
}

export function getPostList(data) {

  const {site_id, post_type_id, limit, offset, sort} = data

  return httpPost("/post/list", {
    "post_type_id": post_type_id,
    "limit": limit,
    "offset": offset,
    'sort': sort,
  }, {
    "cms-site-id": site_id,
  });
}

export function createType(data) {

  const {site_id, name, front_api_flg, search_flg, expand_value} = data

  return httpPost("/post/type/create", {
    "name": name,
    "front_api_flg": front_api_flg,
    "search_flg": search_flg,
    "expand_value": expand_value,
  }, {
    "cms-site-id": site_id,
  });
}

export function updateType(data) {

  const {site_id, post_type_id, name, front_api_flg, search_flg, expand_value} = data

  return httpPost("/post/type/update", {
    "post_type_id": post_type_id,
    "name": name,
    "front_api_flg": front_api_flg,
    "search_flg": search_flg,
    "expand_value": expand_value,
  }, {
    "cms-site-id": site_id,
  });
}

export function deleteType(data) {

  const {site_id, post_type_id} = data

  return httpPost("/post/type/delete", {
    "post_type_id": post_type_id,
  }, {
    "cms-site-id": site_id,
  });
}

export function createPost(data) {

  const {site_id,
    post_type_id,
    content,
    title,
    open_flg,
    open_time,
    end_time,
    url_category_id,
    category_ids,
    tags,
    url_key,
    author_user_key,
    expand_value,
    expand_list_value} = data

  return httpPost("/post/create", {
    "post_type_id": post_type_id,
    "content": content,
    "title": title,
    "open_flg": open_flg,
    "open_time": open_time,
    "end_time": end_time,
    "url_category_id": url_category_id,
    "category_ids": category_ids,
    "tags": tags,
    "url_key": url_key,
    "author_user_key": author_user_key,
    "expand_value": expand_value,
    "expand_list_value": expand_list_value,
  }, {
    "cms-site-id": site_id,
  });
}

export function updatePost(data) {

  const {site_id,
    post_type_id,
    post_id,
    content,
    title,
    open_flg,
    open_time,
    end_time,
    url_category_id,
    category_ids,
    tags,
    url_key,
    author_user_key,
    expand_value,
    expand_list_value} = data

  return httpPost("/post/update", {
    "post_type_id": post_type_id,
    "post_id": post_id,
    "url_key": url_key,
    "content": content,
    "title": title,
    "open_flg": open_flg,
    "open_time": open_time,
    "end_time": end_time,
    "url_category_id": url_category_id,
    "category_ids": category_ids,
    "tags": tags,
    "author_user_key": author_user_key,
    "expand_value": expand_value,
    "expand_list_value": expand_list_value,
  }, {
    "cms-site-id": site_id,
  });
}

export function deletePost(data) {

  const {site_id, post_type_id, post_id} = data

  return httpPost("/post/delete", {
    "post_type_id": post_type_id,
    "post_id": post_id,
  }, {
    "cms-site-id": site_id,
  });
}

export function changeRank(data) {

  const {site_id, post_type_id, src_post_id, to_post_id, is_after} = data

  return httpPost("/post/change_rank", {
    "post_type_id": post_type_id,
    "src_post_id": src_post_id,
    "to_post_id": to_post_id,
    "is_after": is_after,
  }, {
    "cms-site-id": site_id,
  });
}

export function updatePostOptionMaster(data) {

  const {site_id,
    post_type_id,
    post_text,
    post_array_text,
    post_list_text,
    post_list_array,
    category_text,
    category_array,
    category_list_text,
    category_list_array} = data

  return httpPost("/post/option_master", {
    "post_type_id": post_type_id,
    "post_text": post_text,
    "post_array_text": post_array_text,
    "post_list_text": post_list_text,
    "post_list_array": post_list_array,
    "category_text": category_text,
    "category_array": category_array,
    "category_list_text": category_list_text,
    "category_list_array": category_list_array,
  }, {
    "cms-site-id": site_id,
  });
}

export function changeTopLastRank(data) {

  const {site_id, post_type_id, src_post_id, is_top} = data

  return httpPost("/post/change_top_last_rank", {
    "post_type_id": post_type_id,
    "src_post_id": src_post_id,
    "is_top": is_top,
  }, {
    "cms-site-id": site_id,
  });
}

export function useGetPostType(site_id, type) {

  const shouldFetch = Boolean(site_id && type);

  const {data, isLoading, error, isValidating, mutate} = useSWR(shouldFetch ? {site_id, type} : null, getType)

  const memoizedValue = useMemo(
    () => ({
      postType: data || null,
      postTypeLoading: isLoading,
      postTypeError: error,
      postTypeValidating: isValidating,
      postTypeRefresh: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue

}

export function useGetPosts(site_id, datas) {


  const {post_type_id, limit, offset} = datas

  const shouldFetch = Boolean(site_id && post_type_id);

  const {data, isLoading, error, isValidating, mutate} = useSWR(shouldFetch ? {site_id, post_type_id, limit, offset} : null, getPostList)

  const memoizedValue = useMemo(
    () => ({
      posts: data?.post_list || [],
      postsCount: data?.count || 0,
      postsLoading: isLoading,
      postsError: error,
      postsValidating: isValidating,
      postsEmpty: !isLoading && !data?.count,
      postsRefresh: mutate,
    }),
    [mutate, data, error, isLoading, isValidating]
  );

  return memoizedValue
}

export function useGetPost(site_id, post_type_id, post_id) {

  const {data, isLoading, error, isValidating} = useSWR({site_id, post_type_id, post_id}, getPost)

  const memoizedValue = useMemo(
    () => ({
      post: data || null,
      postLoading: isLoading,
      postError: error,
      postValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue
}
