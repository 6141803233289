import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { SiteProvider } from '../../components/site/context/site-provider';
import SiteView from '../../components/site/site-view';
import { PostProvider } from '../../components/site/post/context';
import PostView from '../../components/site/post/post-view';
import PageView from '../../components/site/page/page-view';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// CMS POST
const PostsPage = lazy(() => import('src/pages/dashboard/posts/list'));
const PostsNewPage = lazy(() => import('src/pages/dashboard/posts/new'));
const PostsEditPage = lazy(() => import('src/pages/dashboard/posts/edit'));
const PostsCategoryPage = lazy(() => import('src/pages/dashboard/posts/category/list'));
const PostsCategoryCreatePage = lazy(() => import('src/pages/dashboard/posts/category/new'));
const PostsCategoryAddSonCreatePage = lazy(() => import('src/pages/dashboard/posts/category/new-son'));
const PostsCategoryEditPage = lazy(() => import('src/pages/dashboard/posts/category/edit'));
// CMS PAGE
const PageEditPage = lazy(() => import('src/pages/dashboard/page/edit'));
const PageAddPage = lazy(() => import('src/pages/dashboard/page/add'));
const PagePostAddPage = lazy(() => import('src/pages/dashboard/page/post-add'));
// CMS SITE
const SiteEditPage = lazy(() => import('src/pages/dashboard/site/edit'));
// CMS POST TYPE
const PostTypeEditPage = lazy(() => import('src/pages/dashboard/post-type/edit'));
const PostTypeAddPage = lazy(() => import('src/pages/dashboard/post-type/add'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
const StorageManagerPage = lazy(() => import('src/pages/dashboard/storage-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// CONFIG
const ConfigListPage = lazy(() => import('src/pages/dashboard/config/list'));
const ConfigSitePage = lazy(() => import('src/pages/dashboard/config/site/site'));
const ConfigSiteLayoutPage = lazy(() => import('src/pages/dashboard/config/site/site-layout'));
const ConfigSiteLayoutListPage = lazy(() => import('src/pages/dashboard/config/site/site-layout-list'));

const ConfigPostLayoutPage = lazy(() => import('src/pages/dashboard/config/post/post-layout'));
const ConfigPostLayoutListPage = lazy(() => import('src/pages/dashboard/config/post/post-layout-list'));
const ConfigPostPage = lazy(() => import('src/pages/dashboard/config/post/post'));

const ConfigPostCategoryLayoutPage = lazy(() => import('src/pages/dashboard/config/post-category/post-category-layout'));
const ConfigPostCategoryLayoutListPage = lazy(() => import('src/pages/dashboard/config/post-category/post-category-layout-list'));
const ConfigPostCategoryPage = lazy(() => import('src/pages/dashboard/config/post-category/post-category'));

const ConfigPagePage = lazy(() => import('src/pages/dashboard/config/page/page'));
const ConfigPageLayoutPage = lazy(() => import('src/pages/dashboard/config/page/page-layout'));
const ConfigPageLayoutListPage = lazy(() => import('src/pages/dashboard/config/page/page-layout-list'));
const ConfigPostTypePage = lazy(() => import('src/pages/dashboard/config/post-type/post-type'));
const ConfigPostTypeLayoutPage = lazy(() => import('src/pages/dashboard/config/post-type/post-type-layout'));
const ConfigPostTypeLayoutListPage = lazy(() => import('src/pages/dashboard/config/post-type/post-type-layout-list'));
const ConfigPostPreviewEditPage = lazy(() => import('src/pages/dashboard/config/post/post-preview-edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <SiteProvider>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </SiteProvider>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'user',
        children: [
          { path: 'edit', element: <UserEditPage />, index: true },
        ],
      },
      {
        path: 'product',
        element: (
          <SiteView>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </SiteView>
        ),
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        element: (
          <SiteView>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </SiteView>
        ),
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        element: (
          <SiteView>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </SiteView>
        ),
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        element: (
          <SiteView>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </SiteView>
        ),
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },

      {
        path: 'site/:site_code',
        element: (
          <SiteView>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </SiteView>
        ),
        children: [
          { element: <IndexPage />, index: true },
          {
            path: 'posts',
            element: (
              <SiteView>
                <PostProvider>
                  <PostView>
                    <Suspense fallback={<LoadingScreen />}>
                      <Outlet />
                    </Suspense>
                  </PostView>
                </PostProvider>
              </SiteView>
            ),
            children: [
              { path: ':post_type_id', element: <PostsPage />, index: true },
              { path: ':post_type_id/list', element: <PostsPage /> },
              { path: ':post_type_id/new', element: <PostsNewPage /> },
              { path: ':post_type_id/:id/edit', element: <PostsEditPage /> },
              { path: ':post_type_id/category/list', element: <PostsCategoryPage /> },
              { path: ':post_type_id/category/new', element: <PostsCategoryCreatePage /> },
              { path: ':post_type_id/category/:id/new-son', element: <PostsCategoryAddSonCreatePage /> },
              { path: ':post_type_id/category/:id/edit', element: <PostsCategoryEditPage /> },
            ],
          },
          {
            path: 'config',
            element: (
              <SiteView>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </SiteView>
            ),
            children: [
              { path: 'list', element: <ConfigListPage />, index: true },
              { path: 'site/:name', element: <ConfigSitePage /> },
              { path: 'site/layout', element: <ConfigSiteLayoutPage /> },
              { path: 'site/layout/list', element: <ConfigSiteLayoutListPage /> },

              { path: 'post/:post_type_id/layout', element: <ConfigPostLayoutPage /> },
              { path: 'post/:post_type_id/layout/list', element: <ConfigPostLayoutListPage /> },
              { path: 'post/:post_type_id/preview-edit', element: <ConfigPostPreviewEditPage /> },
              { path: 'post/:post_type_id/:name', element: <ConfigPostPage /> },

              { path: 'post-category/:post_type_id/layout', element: <ConfigPostCategoryLayoutPage /> },
              { path: 'post-category/:post_type_id/layout/list', element: <ConfigPostCategoryLayoutListPage /> },
              { path: 'post-category/:post_type_id/:name', element: <ConfigPostCategoryPage /> },

              { path: 'page/:page_pkg_code/:page_id/:name', element: <ConfigPagePage />},
              { path: 'page/:page_pkg_code/:page_id/layout', element: <ConfigPageLayoutPage />},
              { path: 'page/:page_pkg_code/:page_id/layout/list', element: <ConfigPageLayoutListPage /> },
              { path: 'post-type/:post_type_id/:name', element: <ConfigPostTypePage /> },
              { path: 'post-type/:post_type_id/layout', element: <ConfigPostTypeLayoutPage /> },
              { path: 'post-type/:post_type_id/layout/list', element: <ConfigPostTypeLayoutListPage /> },

              { path: 'post-type-add', element: <PostTypeAddPage />},
              { path: 'page-add', element: <PageAddPage /> },
              { path: 'page-post-add', element: <PagePostAddPage /> },
            ],
          },
          {
            path: 'page',
            element: (
              <SiteView>
                <PageView>
                  <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                  </Suspense>
                </PageView>
              </SiteView>
            ),
            children: [
              { path: ':id/edit', element: <PageEditPage /> },
            ],
          },
          {
            path: 'edit',
            element: (
              <SiteView>
                <Suspense fallback={<LoadingScreen />}>
                  <SiteEditPage />
                </Suspense>
              </SiteView>
            ),
          },
          {
            path: 'post-type',
            element: (
              <SiteView>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </SiteView>
            ),
            children: [
              { path: ':id/edit', element: <PostTypeEditPage /> },
            ],
          },
          { path: 'storage-manager', element:
              <SiteView>
                <StorageManagerPage />
              </SiteView>
          },
        ],
      },



      {
        path: 'job',
        element: (
          <SiteView>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </SiteView>
        ),
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        element: (
          <SiteView>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </SiteView>
        ),
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element:
          <SiteView>
            <FileManagerPage />
          </SiteView>
        },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
