import useSWR from 'swr';
import { useMemo } from 'react';

import { httpGet, httpPost } from 'src/utils/cms-axios';

export function getAll(data) {

  const { site_ids } = data

  return httpPost("/user_acl/all", {
    site_ids,
  });
}

export function getSites() {
  return httpGet("/user_acl/sites");
}

export function getSiteUsers(data) {

  const { site_id } = data

  return httpGet("/user_acl/site_users", {}, {
    "cms-site-id": site_id,
  });
}

export function getSite(data) {

  const { site_id } = data

  return httpGet("/user_acl/site", {}, {
    "cms-site-id": site_id,
  });
}