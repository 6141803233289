import { get } from '../cms-api/site';

export async function getSiteData(site_id) {
  const siteData = await get({ site_id })

  if (siteData.expand_value?.text?.private_sys_admin_config) {

    const configData = JSON.parse(siteData.expand_value.text.private_sys_admin_config);

    configData.posts = configData.posts || [];
    configData.page = configData.page || { page: [] };
    configData.post_type = configData.post_type || [];
    configData.site = configData.site || { view: [], edit: {} };

    return {
      siteData,
      configData,
    }
  }

  return {
    siteData,
    configData: {
      site_id,
      posts: [],
      page: {
        page: [],
      },
      post_type: [],
      site: {
        view: [],
        edit: {},
      },
    }
  }
}
