import PropTypes from 'prop-types';
import { useSiteContext } from '../context/site-context';
import { usePostContext } from './context';
import { SplashScreen } from '../../loading-screen';

export default function PostView({ children }) {
  const {site} = useSiteContext();

  const {postTypeConfig, postTypeIsLoading} = usePostContext()

  if (postTypeIsLoading) {
    return <SplashScreen />;
  }

  if (!site) {
    return <div>サイトを選択してください。</div>;
  }

  if (!postTypeConfig) {
    return <div>POST TYPEを選択してください。</div>
  }

  return children
}

PostView.propTypes = {
  children: PropTypes.node,
};