import { useCallback, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import cookies from "js-cookie";
import { isValidToken } from './utils';
import cmsaxios from '../../../utils/cms-axios';
import { AuthContext } from './auth-context';
import { useSearchParams } from '../../../routes/hook';
import { useGetUser } from '../../../cms-api/user';

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

const STORAGE_KEY = 'api_key';

export function AuthProvider({ children }) {

  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = cookies.get(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {

        // setSession(accessToken);

        const response = await cmsaxios.get('/api/user');

        if (response.data.status === "OK") {

          dispatch({
            type: 'INITIAL',
            payload: {
              user: response.data,
            },
          });

          const returnTo = cookies.get('returnTo');

          if (returnTo) {
            cookies.remove("returnTo")
            window.location.replace(returnTo);
          }
        } else {
          console.error(response.data.error);
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }

      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (appState) => {
    const response = await cmsaxios.get('/login');
    if (response.data.status !== 'OK') {
      throw new Error(response.data);
    }
    cookies.set("returnTo", appState?.returnTo)
    window.location.href = response.data.url;
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    const response = await cmsaxios.get('/logout');
    if (response.data.status !== 'OK') {
      throw new Error(response.data);
    }

    window.location.href = response.data.url;
  }, []);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'cms',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [login, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};